export const border = {
	radius: {
		'2': 2,
		'4': 4,
		'8': 8,
		'12': 12,
		'16': 16,
		'24': 24,
		'32': 32,
		'48': 48,
	},
};
