import React from 'react';
const ForgotPasswordPage = React.lazy(() => import('pages/ForgotPasswordPage'));
const LogoutPage = React.lazy(() => import('pages/LogoutPage'));

// import LogoutPage from 'pages/LogoutPage';
// import ForgotPasswordPage from 'pages/ForgotPasswordPage';n
const ClientsPage = React.lazy(() => import('pages/ClientsPage'));
const LoginPage = React.lazy(() => import('pages/LoginPage'));
const ProjectsPage = React.lazy(() => import('pages/ProjectsPage'));
const CreateProjectPage = React.lazy(() => import('pages/ProjectsPage/CreateProjectPage'));
const BoardPage = React.lazy(() => import('pages/ProjectsPage/BoardPage'));
const DashboardPage = React.lazy(() => import('pages/ProjectsPage/DashboardPage'));
const ReportPage = React.lazy(() => import('pages/ProjectsPage/ReportPage'));
const CreateReportPage = React.lazy(() => import('pages/ProjectsPage/CreateReportPage'));
const ProcessedDataPage = React.lazy(() => import('pages/ProjectsPage/ProcessedDataPage'));
const FileSyncPage = React.lazy(() => import('pages/ProjectsPage/FileSyncPage'));
const SettingPage = React.lazy(() => import('pages/ProjectsPage/SettingPage'));
const CreateColumnPage = React.lazy(() => import('pages/ProjectsPage/CreateColumnPage'));
const CreateEventListPage = React.lazy(() => import('pages/ProjectsPage/CreateEventListPage'));
const EventsLogTablePage = React.lazy(() => import('pages/ProjectsPage/EventsLogTablePage'));
const CreateOverlayPage = React.lazy(() => import('pages/ProjectsPage/CreateOverlayPage'));
const WorkspacePage = React.lazy(() => import('pages/WorkspacePage'));
const MyProfilePage = React.lazy(() => import('pages/MyProfilePage'));
const ChangePasswordPage = React.lazy(() => import('pages/ChangePasswordPage'));
const ChangeIPPage = React.lazy(() => import('pages/ChangeIPPage'));
const ViewerPage = React.lazy(() => import('pages/ProjectsPage/ViewerPage'));

const routes: Page.IRoute[] = [
	{
		type: 'public',
		name: 'login',
		path: '/',
		component: LoginPage,
		exact: true,
	},
	{
		type: 'public',
		name: 'logout',
		path: '/logout',
		component: LogoutPage,
		exact: true,
	},
	{
		type: 'public',
		name: 'forgotPassword',
		path: '/forgot-password',
		component: ForgotPasswordPage,
		exact: true,
	},
	{
		type: 'public',
		name: 'clients',
		path: '/client',
		component: ClientsPage,
		exact: true,
	},
	{
		type: 'public',
		name: 'client-projects',
		path: '/client/:clientId/projects',
		component: ProjectsPage,
		exact: true,
	},
	{
		type: 'public',
		name: 'client-create-project',
		path: '/client/:clientId/create-project',
		component: CreateProjectPage,
		exact: true,
	},
	{
		type: 'public',
		name: 'client-project-board',
		path: '/client/:clientId/project/:projectId/board',
		component: BoardPage,
		exact: true,
	},
	{
		type: 'public',
		name: 'client-project-reports',
		path: '/client/:clientId/project/:projectId/report',
		component: ReportPage,
		exact: true,
	},
	{
		type: 'public',
		name: 'client-project-viewer',
		path: '/client/:clientId/project/:projectId/viewer',
		component: ViewerPage,
		exact: true,
	},
	{
		type: 'public',
		name: 'dashboard',
		path: '/client/:clientId/project/:projectId/dashboard',
		component: DashboardPage,
		exact: true,
	},
	{
		type: 'public',
		name: 'event',
		path: '/client/:clientId/project/:projectId/event',
		component: DashboardPage,
		exact: true,
	},
	{
		type: 'public',
		name: 'client-project-reports',
		path: '/client/:clientId/project/:projectId/preview-report',
		component: CreateReportPage,
		exact: true,
	},
	{
		type: 'public',
		name: 'client-project-processed-data',
		path: '/client/:clientId/project/:projectId/processed-data',
		component: ProcessedDataPage,
		exact: true,
	},
	{
		type: 'public',
		name: 'client-project-settings',
		path: '/client/:clientId/project/:projectId/settings',
		component: SettingPage,
		exact: true,
	},
	{
		type: 'public',
		name: 'client-project-file-sync',
		path: '/client/:clientId/project/:projectId/file-sync',
		component: FileSyncPage,
		exact: true,
	},
	{
		type: 'public',
		name: 'client-project-create-column',
		path: '/client/:clientId/project/:projectId/settings/create-column',
		component: CreateColumnPage,
		exact: true,
	},
	{
		type: 'public',
		name: 'client-project-create-event-list',
		path: '/client/:clientId/project/:projectId/settings/create-event-list',
		component: CreateEventListPage,
		exact: true,
	},
	{
		type: 'public',
		name: 'client-project-create-column',
		path: '/client/:clientId/project/:projectId/settings/events-log-table',
		component: EventsLogTablePage,
		exact: true,
	},
	{
		type: 'public',
		name: 'client-project-create-overlay',
		path: '/client/:clientId/project/:projectId/settings/create-overlay',
		component: CreateOverlayPage,
		exact: true,
	},
	{
		type: 'public',
		name: 'workspace',
		path: '/client/:clientId/project/:projectId/item/:itemId/ws-list',
		component: WorkspacePage,
		exact: true,
	},
	{
		type: 'public',
		name: 'profile',
		path: '/profile/my-profile',
		component: MyProfilePage,
		exact: true,
	},
	{
		type: 'public',
		name: 'profile',
		path: '/profile/change-ip',
		component: ChangeIPPage,
		exact: true,
	},
	{
		type: 'public',
		name: 'profile',
		path: '/profile/change-password',
		component: ChangePasswordPage,
		exact: true,
	},
	{
		type: 'redirect',
		from: '*',
		to: '/',
	},
];

export default routes;
