// import './themes/_bootstrap.scss';
import '@progress/kendo-theme-material/dist/all.css';
import './themes/xspector-v2.css';
import './themes/main.css';
import 'react-tooltip/dist/react-tooltip.css';

import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(tz);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const render = () => {
	return (
		<StrictMode>
			<App />
		</StrictMode>
	);
};
root.render(render());

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
