/* eslint no-console: 0 */ // --> OFF

import { Redirect, Route } from 'react-router-dom';

export const renderRoute = (routes: Page.IRoute[]): JSX.Element[] => {
	return routes.map(({ type, ...route }, index) => {
		switch (type) {
			case 'public':
			case 'private': {
				const { component, path, exact } = route as Page.IPublicRoute;

				return <Route key={`route-${index}`} component={component} path={path} exact={exact} />;
			}
			case 'redirect':
			default: {
				const { from, to } = route as Page.IRedirectRoute;

				return <Redirect key={`route-${index}`} from={from} to={to} />;
			}
		}
	});
};
