const texts = {
	h1: {
		fontFamily: 'Poppins',
		fontSize: '64px',
		lineHeight: '80px',
		fontWeight: 700,
	},
	h2: {
		fontFamily: 'Poppins',
		fontSize: '48px',
		lineHeight: '64px',
		fontWeight: 700,
	},
	h3: {
		fontFamily: 'Poppins',
		fontSize: '32px',
		lineHeight: '48px',
		fontWeight: 700,
	},
	h4: {
		fontFamily: 'Poppins',
		fontSize: '24px',
		lineHeight: '36px',
		fontWeight: 700,
	},
	h5: {
		fontFamily: 'Poppins',
		fontSize: '20px',
		lineHeight: '30px',
		fontWeight: 700,
	},
	h6: {
		fontFamily: 'Poppins',
		fontSize: '18px',
		lineHeight: '28px',
		fontWeight: 700,
	},
	h7: {
		fontFamily: 'Poppins',
		fontSize: '14px',
		lineHeight: '22px',
		fontWeight: 600,
	},
	subtitle1: {
		fontFamily: 'Poppins',
		fontSize: '16px',
		lineHeight: '24px',
		fontWeight: 600,
	},
	subtitle2: {
		fontFamily: 'Poppins',
		fontSize: '14px',
		lineHeight: '22px',
		fontWeight: 700,
	},
	subtitle3: {
		fontFamily: 'Poppins',
		fontSize: '12px',
		lineHeight: '14px',
		fontWeight: 400,
	},
	subtitle5: {
		fontFamily: 'Poppins',
		fontSize: '14px',
		lineHeight: '22px',
		fontWeight: 500,
	},
	body1: {
		fontFamily: 'Poppins',
		fontSize: '16px',
		lineHeight: '24px',
		fontWeight: 400,
	},
	body2: {
		fontFamily: 'Poppins',
		fontSize: '14px',
		lineHeight: '22px',
		fontWeight: 400,
	},
	body3: {
		fontFamily: 'Poppins',
		fontSize: '14px',
		lineHeight: '18px',
		fontWeight: 400,
	},
	body4: {
		fontFamily: 'Poppins',
		fontSize: '16px',
		lineHeight: '20px',
		fontWeight: 500,
	},
	buttonLarge: {
		fontFamily: 'Poppins',
		fontSize: '15px',
		lineHeight: '26px',
		fontWeight: 700,
	},
	buttonMedium: {
		fontFamily: 'Poppins',
		fontSize: '14px',
		lineHeight: '24px',
		fontWeight: 700,
	},
	buttonSmall: {
		fontFamily: 'Poppins',
		fontSize: '13px',
		lineHeight: '22px',
		fontWeight: 700,
	},
	caption: {
		fontFamily: 'Poppins',
		fontSize: '12px',
		lineHeight: '18px',
		fontWeight: 600,
	},
	label: {
		fontFamily: 'Poppins',
		fontSize: '10px',
		lineHeight: '18px',
		fontWeight: 500,
	},
	subLabel: {
		fontFamily: 'Poppins',
		fontSize: '12px',
		lineHeight: '20px',
		fontWeight: 400,
	},
	overline: {
		fontFamily: 'Poppins',
		fontSize: '12px',
		lineHeight: '18px',
		fontWeight: 700,
	},
	cardItem: {
		fontFamily: 'Poppins',
		fontSize: '12px',
		lineHeight: '16px',
		fontWeight: 400,
	},
	tableBodyLabel: {
		fontFamily: 'Poppins',
		fontSize: '10px',
		lineHeight: '10px',
		fontWeight: 400,
	},
	smallHeader: {
		fontFamily: 'Poppins',
		fontSize: '10px',
		lineHeight: '12px',
		fontWeight: 500,
	},
	detail: {
		fontFamily: 'Poppins',
		fontSize: '12px',
		lineHeight: '14px',
		fontWeight: 500,
	},
	statusButton: {
		fontFamily: 'Poppins',
		fontSize: '10px',
		lineHeight: '10px',
		fontWeight: 400,
	},
};

export default texts;
