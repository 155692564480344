import { createTheme, ThemeOptions } from '@mui/material';
import { texts, colors } from './utils';
const xspectorThemeConfig: ThemeOptions = {
	palette: {
		mode: 'dark',
		primary: {
			light: '#ECF576',
			main: '#A89D00', //DBDB23
			dark: '#DBDB23',
			contrastText: '#212B36',
		},
	},
	components: {
		MuiCssBaseline: {
			defaultProps: {
				enableColorScheme: true,
			},
		},
		MuiButtonBase: {
			defaultProps: {
				disableTouchRipple: false,
			},
		},
		MuiButton: {
			defaultProps: {
				disableElevation: true,
			},
			styleOverrides: {
				sizeSmall: {
					height: 30,
					borderRadius: 8,
				},
				sizeMedium: {
					height: 38,
					borderRadius: 8,
				},
				sizeLarge: {
					height: 48,
					borderRadius: 8,
				},
			},
		},
		MuiMenu: {
			styleOverrides: {
				root: {
					marginTop: 8,
					zIndex: 9999,
				},
				paper: {
					borderRadius: '8px !important',
				},
				list: {
					padding: 0,
				},
			},
		},

		MuiMenuItem: {
			styleOverrides: {
				root: {
					borderRadius: 0,
					padding: 16,
					gap: 16,
					...texts.body2,
					'&.Mui-selected': {
						backgroundColor: '#3E3D40',
						'&.Mui-focusVisible': { background: '#3E3D40' },
						':hover': {
							backgroundColor: '#575759',
						},
					},
					':hover': {
						backgroundColor: '#575759',
					},
					':active': {
						backgroundColor: '#3E3D40',
					},
					'.MuiTouchRipple-rippleVisible': {
						color: '#575759 !important',
					},
				},
				divider: {
					borderColor: colors.divider.ondark.default,
				},
			},
		},
		MuiSnackbar: {
			styleOverrides: {
				anchorOriginTopCenter: {
					top: '7px !important',
				},
			},
		},
		MuiList: {
			styleOverrides: {
				root: {
					// border: '1px solid rgba(124, 138, 153, 0.63)',
					borderRadius: 4,
					'& > li': {
						minHeight: 50,
					},
				},
			},
		},
		MuiCheckbox: {
			styleOverrides: {
				root: {
					color: '#aaaaaa',
				},
			},
		},
		MuiSelect: {
			styleOverrides: {
				select: {
					// marginTop: 16,
					// border: '1px solid rgba(124, 138, 153, 0.63)',
					// color: 'white',
					// borderRadius: 8,
					// margin: 0,
				},
			},
		},
		MuiInputLabel: {
			styleOverrides: {
				root: {
					color: 'white',
					'&.Mui-focused': {
						color: 'white',
					},
				},
				shrink: {
					color: '#dfe3e8',
					backgroundColor: 'rgba(49, 57, 65, 0.1)',
					' -webkit-backdrop-filter': 'blur(10px)',
					backdropFilter: 'blur(10px)',
				},
			},
		},
		MuiSnackbarContent: {
			styleOverrides: {
				root: {
					backgroundColor: 'rgb(45, 46, 48)',
				},
			},
		},
		MuiPaper: {
			styleOverrides: {
				root: {
					backgroundImage: 'none',
				},
			},
		},
		MuiInputBase: {
			styleOverrides: {
				root: {
					borderRadius: '8px !important',
				},
			},
		},
	},

	typography: {
		fontFamily: 'Poppins',
		button: {
			textTransform: 'none',
			fontWeight: 700,
			letterSpacing: 0,
			fontSize: 15,
			lineHeight: '26px',
		},
	},
};
const xspectorTheme = createTheme(xspectorThemeConfig);

export default xspectorTheme;
