import { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import RouterHandle from 'router';
import xspectorTheme from 'themes/xspectorTheme';
import { RecoilRoot } from 'recoil';
import RecoilNexus from 'recoil-nexus';
import { ThemeProvider } from '@mui/material';

function App() {
	useEffect(() => {
		const isLoggedIn = JSON.parse(localStorage.getItem('isLoggedIn') ?? 'null');

		if (isLoggedIn === false) {
			localStorage.removeItem('accessToken');
		}
	}, []);

	return (
		<RecoilRoot>
			<RecoilNexus />
			<ThemeProvider theme={xspectorTheme}>
				<Router>
					<div className="app-container">
						<RouterHandle />
					</div>
				</Router>
			</ThemeProvider>
		</RecoilRoot>
	);
}

export default App;
