import { FC, Suspense } from 'react';
import { Switch, BrowserRouter as Router } from 'react-router-dom';

import { renderRoute } from 'utils/jsxFunctions';

import routes from './main.routes';

const AppRouter: FC = () => {
	return (
		<>
			<Router>
				<Suspense>
					<Switch>{renderRoute(routes)}</Switch>
				</Suspense>
			</Router>
		</>
	);
};

export default AppRouter;
